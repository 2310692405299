import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div>
          <div className="container">
          <div className="row justify-content-center">
              <div className="col-md-12 intro-logo">
                <img src="img/sgs_logo.png" className="img-logo" alt="" />{" "}
                {/* <h1>
                  Test
                </h1> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 intro-text">
                {/* <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1> */}
                <p><span className="intro-subtitle" style={{fontFamily: "math", fontWeight: "800"}}>T</span><span className="intro-subtitle">{props.data ? props.data.paragraph : "Loading"}</span></p>
                {/* <a
                  href="#contact"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Contattaci
                </a>{" "} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
