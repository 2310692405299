import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);

    const emailAddress = props.data ? props.data.email1 : "info@globalsecuritysat.it";
    let url = "mailto:"
      + emailAddress
      + "?subject=" + "info da " + name + " (" + email + ")"
      + "&body=" + message;

    //window.location.href = url;
    console.log(url);
    window.open(url);

  };
  return (
      <div id="contact">
        <div className="container">
          {/* <div className="col-md-12"> */}
            <div className="row justify-content-center">
              <div className="col-md-12">
              <div className="section-title">
                <h2>Teniamoci in contatto</h2>
                {/* <p>
                  Riempi il form seguente per inviarci un'e-mail e ti risponderemo
                  il prima possibile.
                </p> */}
              </div>
              <div className="contact-item">
              <p>
                <span style={{marginBottom: "25px"}}>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <span style={{marginBottom: "10px"}}>
                  {props.data ? <a href={'mailto:' + props.data.email1}>{props.data.email1}</a> : "loading"}
                </span>
                <span style={{marginBottom: "10px"}}>
                {props.data ? <a href={'mailto:' + props.data.email2}>{props.data.email2}</a> : "loading"}
                </span>
                <span style={{marginBottom: "10px"}}>
                  {props.data ? <a href={'mailto:' + props.data.email3}>{props.data.email3}</a> : "loading"}
                </span>
                <span style={{marginBottom: "10px"}}>
                  {props.data ? <a href={'mailto:' + props.data.email4}>{props.data.email4}</a> : "loading"}
                </span>
                </p>
            </div>
            </div>
              {/* <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Il tuo nome"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="La tua e-mail"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Testo del messaggio"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Invia e-mail
                </button>
              </form> */}
            {/* </div> */}
          </div>
          {/* <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informazioni contatto</h3>
            </div>
            <div className="contact-item">
              <p>
                <span style={{marginBottom: "25px"}}>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <span style={{marginBottom: "10px"}}>
                  {props.data ? <a href={'mailto:' + props.data.email1}>{props.data.email1}</a> : "loading"}
                </span>
                <span style={{marginBottom: "10px"}}>
                {props.data ? <a href={'mailto:' + props.data.email2}>{props.data.email2}</a> : "loading"}
                </span>
                <span style={{marginBottom: "10px"}}>
                  {props.data ? <a href={'mailto:' + props.data.email3}>{props.data.email3}</a> : "loading"}
                </span>
                <span style={{marginBottom: "10px"}}>
                  {props.data ? <a href={'mailto:' + props.data.email4}>{props.data.email4}</a> : "loading"}
                </span>
                </p>
            </div>
          </div> */}
        </div>
      </div>
  );
};
